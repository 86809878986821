import React from "react";
import ProImage01 from "../images/product/pro01.png";
import ProImage02 from "../images/product/pro02.png";
import ProImage03 from "../images/product/pro03.png";
import ProImage04 from "../images/product/pro04.png";
import ProImage05 from "../images/product/pro05.png";
import ProImage06 from "../images/product/pro06.png";
import ProImage07 from "../images/product/pro07.png";



export default function ProductGrid(){
	return(
		<>
			<section class="prodct_pg_sec">
				<div class="container">
					<div class="row gx-5 justify-content-center">
						<div class="col-lg-3">
							<div class="categorie_main">
								<ul>
									<li><a href="#"><i class='bx bx-menu-alt-left'></i>All Categories</a></li>
									<li><label class="check-container">Detailing And Polishing<input type="checkbox" /><span class="checkmark"></span></label></li>
									<li><label class="check-container">Solvents And Chemicals<input type="checkbox" /><span class="checkmark"></span></label></li>
									<li><label class="check-container">Solvents And Chemicals<input type="checkbox" /><span class="checkmark"></span></label></li>
									<li><label class="check-container">Tools And Equipment<input type="checkbox" /><span class="checkmark"></span></label></li>
									<li><label class="check-container">Trim Paints<input type="checkbox" /><span class="checkmark"></span></label></li>
									<li><label class="check-container">Paints<input type="checkbox" /><span class="checkmark"></span></label></li>
									<li><label class="check-container">Sanding<input type="checkbox" /><span class="checkmark"></span></label></li>
								</ul>
							</div>
						</div>
						<div class="col-lg-8">
							<div class="product_filter">
								<h2>All Products</h2>
								<div class="filter_select">
									<div class="div">
										<label>Brands</label>
										<select>
											<option value="">All Brands</option>
											<option value="">All Brands</option>
											<option value="">All Brands</option>
										</select>
									</div>
									<div class="div">
										<label>Sorts By</label>
										<select>
											<option value="">Newest</option>
											<option value="">Oldest</option>
										</select>
									</div>
								</div>
							</div>
							<div class="row ">
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
											<img src={ProImage01} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage02} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage03} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage04} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage05} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage06} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage06} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage07} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage01} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										 <img src={ProImage02} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										<img src={ProImage03} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="col-lg-4">	
									<div class="product_main">
										<div class="product_image">
										  <img src={ProImage05} alt=""/>
										</div>
										<div class="product_text">
											<div class="title_price">
												<h4>lorem Ipsum</h4>
												<div>
													<del>1500.00</del>
													<h5>$123.00</h5>
												</div>
											</div>
											<p>Cpisicing elit, sed do </p>
											<ul>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
												<li><i class='bx bxs-star'></i></li>
											</ul>
										</div>
									</div>
								</div>
								<div class="d-flex justify-content-end">
									<ul class="navigation_product">
										<li><i class='bx bx-chevron-left'></i></li>
										<li><a href="#" class="ct-active">1</a></li>
										<li><a href="#">2</a></li>
										<li><a href="#">3</a></li>
										<li><a href="#">4</a></li>
										<li><a href="#">....</a></li>
										<li><a href="#">10</a></li>
										<li><i class='bx bx-chevron-right'></i></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}