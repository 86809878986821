import React from "react";
import ColorForm from "../component/ColorForm";
import MatchingColor from "../component/MatchingColor";

export default function ShopPage(){
	return(
		<>
		<MatchingColor/>
		<ColorForm/>
		</>
	);
}