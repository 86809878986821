import React from "react";
import ShopGrid from "../component/ProductGrid";


export default function ShopPage(){
	return(
		<>
		<ShopGrid/>
		</>
	);
}