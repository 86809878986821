import React from "react";
import MainBanner from "../component/MainBanner.js"
import ProjectSize from "../component/ProjectSize.js"
import About from "../component/About.js"
import CustomColor from "../component/CustomColor.js"
import Testimonial from "../component/Testimonials.js"
import Blogs from "../component/Blogs.js"



export default function HomePage(){
	return(
		<>
		
		<MainBanner/>
		<ProjectSize/>
		<About/>
		<CustomColor/>
		<Testimonial/>
		<Blogs/>
		
		</>
	);
}