import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './pages/HomePage';
import Header from './component/Header.js';
import Footer from './component/Footer.js';
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AboutPage from './pages/AboutPage.jsx';
import ShopPage from './pages/ShopPage.jsx';
import CustomColorPage from './pages/CustomColorPage.jsx';
import FaqPage from './pages/FaqPage.jsx';


function App() {
  return (
    <>
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route  path='/' element={<HomePage/>} />
      <Route  path='/about' element={<AboutPage/>} />
      <Route  path='/shop' element={<ShopPage/>} />
      <Route  path='/customcolor' element={<CustomColorPage/>} />
      <Route  path='/faq' element={<FaqPage/>} />

    </Routes>
    <Footer/>
    </BrowserRouter>
    </>
  );
}

export default App;
